module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-reference-footnotes","options":{}},{"resolve":"gatsby-remark-table-of-contents","options":{"exclude":"Inhaltsverzeichnis|Table of Contents","tight":false,"fromHeading":2,"toHeading":6}},"gatsby-remark-slug",{"resolve":"gatsby-remark-copy-linked-files"},{"resolve":"gatsby-remark-images","options":{"maxWidth":1800}},{"resolve":"gatsby-remark-responsive-iframe"},{"resolve":"gatsby-remark-prismjs"}]},
    },{
      plugin: require('../../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1800,"backgroundColor":"transparent"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Blog","short_name":"GatsbyJS","start_url":"/","background_color":"#ffffff","theme_color":"#663399","display":"minimal-ui","icon":"src/assets/gatsby-icon.png"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
